
import { Component, Vue } from 'vue-property-decorator';
import HeaderLogin from '@/components/HeaderLogin/index.vue';
import FooterLogin from '@/components/FooterLogin/index.vue';

@Component({
    name: "LinkInvalid",
    components: {
        HeaderLogin,
        FooterLogin,
    },
})
export default class LinkInvalid extends Vue {
    private times: number = 5;
    private timer: any = null;
    private created() {
        this.timer = setInterval( () => {
            if (this.times <= 0) {
                clearInterval(this.timer);
                this.times = 5;
                this.$router.push("/login").catch((err) => err);
                return;
            }
            this.times--;
        }, 1000);
    }
}
